$(document).ready(function() {
	//Ready for Theme 4 JS
	console.log('Theme 4 Here');

/*
*  Services List Accordion 
*/

	$( '.services-list-card-title' ).each( function( i ){
		if( i !== 0 ){
			$( this ).addClass( 'services-accordion-closed' );

			if( $( '.services-list-card-grid-container' ).css( 'display' ) !== 'flex' ){
				$( this ).next( '.services-list-content' ).hide();
		   }
		}
	} );

	$( ".services-list-card-title" ).on( 'click', e => {
		var $this = $(e.currentTarget);

		if( $this.hasClass('services-accordion-closed') && $( '.services-list-card-grid-container' ).css( 'display' ) !== 'flex' ){

			$( '.services-list-card-title' ).addClass( 'services-accordion-closed' ).next( '.services-list-content') .slideUp();
			$this.removeClass( 'services-accordion-closed' ).next( '.services-list-content' ).slideDown();	
			
		}
	});

	$(window).resize(()=>{
		if( $('.services-list-card-grid-container' ).css( 'display' ) !== 'flex' ){
				$( '.services-accordion-closed' ).next( '.services-list-content' ).hide();
		} else {
			$( '.services-accordion-closed' ).next( '.services-list-content' ).show();
		}
	});


}); 